import React, { FC, PropsWithChildren, ReactElement, SFC, useEffect, useRef, useState } from 'react'
import cn from 'classname'

const ANIMATE_DURATION = 0.3 * 1000

type DropDownState = {
	active: boolean
}
const DropDown: FC<{
	titleRender: (state: DropDownState) => ReactElement
	slim?: boolean
	invalid?: boolean
	preventOpen?: boolean
	lefted?: boolean
	up?: boolean
}> = ({
	titleRender,
	children,
	invalid = false,
	lefted = false,
	slim = false,
	preventOpen = false,
	up = false,
}) => {
	// const [items, setItems] = useState(props.items);
	const [active, setActive] = useState<boolean>(false)

	const droppable = useRef(null)

	const open = () => {
		if (droppable.current) {
			droppable.current.classList.add(`animate__animated`)
			droppable.current.classList.add(`animate__fadeInUp`)

			setTimeout(() => {
				droppable.current.classList.remove(`animate__animated`)
				droppable.current.classList.remove(`animate__fadeInUp`)
			}, ANIMATE_DURATION)
		}
	}
	const close = () => {
		if (droppable.current) {
			droppable.current.classList.add(`animate__animated`)
			droppable.current.classList.add(`animate__fadeOutDown`)

			setTimeout(() => {
				droppable.current.classList.remove(`animate__animated`)
				droppable.current.classList.remove(`animate__fadeOutDown`)
			}, ANIMATE_DURATION)
		}
	}

	const toggleActive = () => {
		if (!preventOpen) {
			if (!active) {
				if (typeof window.dropDownActiveClose === 'function') {
					window.dropDownActiveClose()
				}

				window.dropDownActiveClose = () => setActive(false)
			}

			const nextState = !active

			setActive(nextState)

			if (nextState) {
				open()
			} else {
				close()
			}
		}
	}

	return (
		<>
			<div
				className={cn(`drop-zone-back`, { active, 'is-invalid': invalid })}
				onClick={toggleActive}
			/>
			<div className={cn(`dropdown`, { active, 'is-invalid': invalid, 'dropdown--slim': slim })}>
				<div className="current" onClick={toggleActive}>
					{titleRender({
						active,
					})}
				</div>

				<div
					ref={droppable}
					className={cn('droppable', {
						lefted,
						up,
					})}
				>
					{typeof children === 'function'
						? children({
								close: () => setActive(false),
								open: () => setActive(true),
						  })
						: children}
				</div>
			</div>
		</>
	)
}

export { DropDown }
